import { errorModal } from "../MasterPage/Pages/Common/Modal";
import { isRejectedWithValue } from "@reduxjs/toolkit";

function getErrorText(action, defaultMsg) {
	// action?.payload?.data?.error
	/**(action?.payload?.data?.error ?? "data" in action?.error
							? action?.error?.data?.message
							: action?.error?.message) */

	const { payload: { data: { error } = {} } = {} } = action ?? {};
	if (error) return error;
	// const { error: { message } = {} } = action ?? {};
	// if (message) return message;

	return defaultMsg;
}

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		let { originalStatus } = action.payload;
		const { status } = action.payload;
		originalStatus = originalStatus ?? status;
		console.log("Error from the middleware", action);
		switch (true) {
			case originalStatus == 400:
				errorModal({ message: getErrorText(action, "Bad request!") });
				break;
			case originalStatus == 401:
				if (window.location.pathname.startsWith("/login")) {
					errorModal({
						message: getErrorText(action, "Invalid username or password!"),
					});
				} else {
					errorModal({ message: getErrorText(action, "Unauthorized access!") });
					window.location.href = window.location.origin + "/login";
				}
				break;
			case originalStatus == 403:
				errorModal({
					message: getErrorText(
						action,
						"You do not have permission to perform this action."
					),
				});
				break;
			case originalStatus == 409:
				errorModal({
					message: getErrorText(action, "Something went wrong! retry later."),
				});
				break;
			case originalStatus == 500:
				errorModal({
					message: getErrorText(action, "Something went wrong! retry later."),
				});
				break;
			default:
				if (getErrorText(action)) {
					errorModal({
						message: getErrorText(action, "Something went wrong! retry later."),
					});
				}
		}
	}

	return next(action);
};

/**
action {
    "type": "appAPI/executeMutation/rejected",
    "payload": {
        "status": "PARSING_ERROR",
        "originalStatus": 404,
        "data": "<!DOCTYPE html>\n<html lang=\"en\">\n<head>\n<meta charset=\"utf-8\">\n<title>Error</title>\n</head>\n<body>\n<pre>Cannot GET /user</pre>\n</body>\n</html>\n",
        "error": "SyntaxError: Unexpected token '<', \"<!DOCTYPE \"... is not valid JSON"
    },
    "meta": {
        "baseQueryMeta": {
            "request": {},
            "response": {}
        },
        "RTK_autoBatch": true,
        "arg": {
            "type": "mutation",
            "endpointName": "getUsers",
            "originalArgs": {
                "count": 5,
                "page": 1
            },
            "track": true
        },
        "requestId": "_EiOaeL3YmJVURMa7esb8",
        "rejectedWithValue": true,
        "requestStatus": "rejected",
        "aborted": false,
        "condition": false
    },
    "error": {
        "message": "Rejected"
    }
}
 */
