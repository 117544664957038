import { useEffect, useRef, useState } from "react";

import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import Load from "../Common/Loader";
import { Outlet } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Stack } from "@mui/material";
import { formatDate } from "../Common/utilityFns";
import response from "./Charts/response.json";
import { updateDashBoard } from "../../../Store/dashboard";
import { useDispatch } from "react-redux";
import { useLazyGetDashBoardQuery } from "../../../services/API/appAPI";

export default function Dashboard() {
	const [fullScreen, setFullScreen] = useState(false);
	const [getDashBoard, dashBoardObj] = useLazyGetDashBoardQuery();
	const [date, setDate] = useState("");
	const dispatch = useDispatch();

	async function getDashBoardList() {
		const res = await getDashBoard();
		dispatch(updateDashBoard({ ...res }));
		setDate(formatDate(new Date()));
	}

	useEffect(() => {
		getDashBoardList();
		setInterval(() => {
			getDashBoardList();
		}, 15 * 60 * 1000);
	}, []);

	function zoomOut() {
		setFullScreen(false);
	}
	function zoomIn() {
		setFullScreen(true);
	}
	return (
		<div
			className={
				fullScreen ? "full dash-board page" : "normal dash-board page"
			}>
			<Load open={dashBoardObj?.isLoading || dashBoardObj?.isFetching} />
			<Stack
				className='actions-dashboard'
				direction={"row"}
				gap={2}
				justifyContent={"center"}
				alignItems={"center"}>
				<div className='title' style={{ fontWeight: 500 }}>
					Last refreshed at : {date}
				</div>
				<RefreshIcon className='refresh-icon' onClick={getDashBoardList} />
				{fullScreen ? (
					<FullscreenExitOutlinedIcon className='zoom-icon' onClick={zoomOut} />
				) : (
					<FullscreenOutlinedIcon className='zoom-icon' onClick={zoomIn} />
				)}
			</Stack>
			<Outlet />
		</div>
	);
}
