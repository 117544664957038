import * as React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EPEXGraph from "./Charts/EPEX";
import IntensityGraph from "./Charts/Intensity";
import PriceGraph from "./Charts/Price";
import { Stack } from "@mui/material";
import { formatDate } from "../Common/utilityFns";
import { getDashboardChartById } from "../../../Store/dashboard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//import CardActions from "@mui/material/CardActions";

//import Button from "@mui/material/Button";

export default function OutlinedCard({ card: chartId }) {
	const navigate = useNavigate();

	const slice = useSelector(getDashboardChartById(chartId));

	// console.log(slice, "aaaaaaa", chartId);

	const name = slice?.activityinfo?.data?.roomdetails?.name ?? "";

	const recipeStatus =
		slice?.activityinfo?.type === "pushed" ? "PUSHED" : "PROCESSED";

	const time = slice?.activityinfo?.time
		? formatDate(slice?.activityinfo?.time)
		: "";

	const isBaseRecipe = slice?.activityinfo?.data?.baseRecipe
		? "Base"
		: "Modified";

	const baseRecipeTotalMoles =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison
			?.base_total_moles ?? "";
	const modifiedRecipeTotalMoles =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison
			?.modified_total_moles ?? "";

	const baseRecipeTotalCost =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison
			?.base_total_cost ?? "";

	const modifiedRecipeTotalCost =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison
			?.modified_total_cost ?? "";

	const molesDiffPercent =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison
			?.molesDiff_percentage ?? "";

	const savings =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison?.savings ?? "";

	return (
		<Box className='card'>
			<Card variant='outlined' className='card'>
				<CardContent>
					<div className='title'>
						<p>Room : {name}</p>
					</div>
					<table>
						<tbody>
							<tr>
								<td>
									<div className='chart-title'>Intensity Chart</div>
								</td>
								<td>
									<div className='info-title'>Information</div>
								</td>
							</tr>
							<tr>
								<td
									onClick={() =>
										navigate("/dashboard/" + chartId + "/intensity-chart")
									}>
									<IntensityGraph chartId={chartId} />
								</td>
								<td>
									<Stack direction={"row"} className='info'>
										<div>
											<table>
												<tbody>
													<tr>
														<td className='info-left'>Recipe Status</td>
														<td>: {recipeStatus}</td>
													</tr>
													<tr>
														<td className='info-left'>Time</td>
														<td>: {time}</td>
													</tr>
													{recipeStatus === "PUSHED" ? (
														<tr>
															<td className='info-left'>Recipe Pushed</td>
															<td>: {isBaseRecipe}</td>
														</tr>
													) : null}
													<tr>
														<td
															className='info-left'
															style={{ textDecoration: "underline" }}>
															Moles
														</td>
													</tr>
													<tr>
														<td className='info-left'>Base Recipe - Total</td>
														<td>: {Number(baseRecipeTotalMoles).toFixed(3)}</td>
													</tr>
													<tr>
														<td className='info-left'>
															Modified Recipe - Total
														</td>
														<td>
															: {Number(modifiedRecipeTotalMoles).toFixed(3)}
														</td>
													</tr>
													<tr>
														<td className='info-left'>Diff (percentage)</td>
														<td>: {molesDiffPercent}%</td>
													</tr>

													<tr>
														<td
															className='info-left'
															style={{ textDecoration: "underline" }}>
															Cost
														</td>
													</tr>
													<tr>
														<td className='info-left'>Base Recipe - Total</td>
														<td>: {Number(baseRecipeTotalCost).toFixed(3)}</td>
													</tr>
													<tr>
														<td className='info-left'>
															Modified Recipe - Total
														</td>
														<td>
															: {Number(modifiedRecipeTotalCost).toFixed(3)}
														</td>
													</tr>
													<tr>
														<td className='info-left'>Savings - Total</td>
														<td>: {Number(savings).toFixed(3)}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</Stack>
								</td>
							</tr>
							<tr>
								<td>
									<div className='chart-title'>EPEX Chart</div>
								</td>
								<td>
									<div className='chart-title'>Price Chart</div>
								</td>
							</tr>
							<tr>
								<td
									onClick={() =>
										navigate("/dashboard/" + chartId + "/epex-chart")
									}>
									<EPEXGraph chartId={chartId} />
								</td>
								<td
									onClick={() =>
										navigate("/dashboard/" + chartId + "/price-chart")
									}>
									<PriceGraph chartId={chartId} />
								</td>
							</tr>
						</tbody>
					</table>
				</CardContent>
				{/* <CardActions>
						<Button size='small'>Learn More</Button>
					</CardActions> */}
			</Card>
		</Box>
	);
}
